import { Container, Col, Row, Form, Button, Offcanvas } from 'react-bootstrap';
import "./Header.css";
import { CiSearch } from "react-icons/ci";
import { CgMenuRight } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import logo from "../../Image/logo.png";
import cartImg from "../../Image/101.svg";
import loginImg from "../../Image/102.svg";
import linImg from "../../Image/lin.svg";
import Cookies from 'js-cookie';
import axiosClient from "../../axios-client";
import toman from "../../Image/toman.svg"

export default function Header() {
    const [show, setShow] = useState(false);
    const [cartCount, setCartCount] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [search, setSearch] = useState('');
    const [searchProduct, setSearchProduct] = useState([]);
    const apiKey = process.env.REACT_APP_API_KEY;

    const searchBoxRef = useRef(null);

    const changeValue = async (e) => {
        const value = e.target.value;
        setSearch(value);

        if (value.length > 2) {
            try {
                const response = await axiosClient.get(`/search?query=${value}`);
                setSearchProduct(response.data);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        } else {
            setSearchProduct([]);
        }
    };

    const handleClickOutside = (event) => {
        if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
            const isLinkClicked = event.target.closest("a");

            if (!isLinkClicked) {
                setSearchProduct([]);
                setSearch('');
            }
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const calculateCartCount = () => {
            const cartFromCookie = Cookies.get('cart');
            if (cartFromCookie) {
                try {
                    const parsedCart = JSON.parse(cartFromCookie);
                    const totalItems = parsedCart.reduce((total, item) => total + item.count, 0);
                    setCartCount(totalItems);
                } catch (e) {
                    console.error("Failed to parse cart data from cookie:", e);
                    setCartCount(0);
                }
            } else {
                setCartCount(0);
            }
        };

        calculateCartCount();
    }, []);

    return (
        <>
            {window.screen.width > 830 ? (
                <header>
                    <div className='desktip-header'>
                        <Container fluid>
                            <Row className='py-4'>
                                <Col lg={3}>
                                    <div className='text-center'>
                                        <Link to={"/"}>
                                            <img src={logo} className='img-fluid' alt="" />
                                        </Link>
                                    </div>
                                </Col>
                                <Col lg={6} style={{ zIndex: "999" }} className='d-flex align-items-center'>
                                    <div className='w-100' style={{ maxHeight: "50px" }}>
                                        <div ref={searchBoxRef} style={{ position: 'relative' }}> {/* Reference added */}
                                            <Form.Control
                                                className='search-input'
                                                placeholder='جستجو...'
                                                type='text'
                                                onChange={changeValue}
                                                value={search}
                                            />
                                            <CiSearch className='search-btn blue' />
                                        </div>
                                        {searchProduct.length > 0 ? (
                                            <div className='search-box-content'>
                                                <Row className='row-cols-3 gy-3'>
                                                    {searchProduct.map((ptc, index) => (
                                                        <Col key={index}>
                                                            <Link to={'/product/' + ptc.slug}>
                                                                <div className='search-card'>
                                                                    <div><img className='img-fluid' src={apiKey + ptc.photo} alt={ptc.name} /></div>
                                                                    <div>{ptc.name}</div>
                                                                    <div className=''>
                                                                        <span className="f-links d-flex align-items-center justify-content-end">
                                                                            <span className="me-2" style={{ marginTop: "10px" }}>
                                                                                {ptc.price > 0 ?
                                                                                    <>

                                                                                        <span className="me-2" style={{ marginTop: "10px" }}>{ptc.price}</span>
                                                                                        <img src={toman} style={{ width: "25px" }} alt="toman" />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <span className='text-danger text-sm'>تماس بگیرید</span>
                                                                                    </>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        ) : (
                                            search.length > 2 && (

                                                <div className='search-box-content'>
                                                    <div className="no-results-found">
                                                        <p className="text-center mt-3">نتیجه‌ای یافت نشد.</p>
                                                    </div>
                                                </div>
                                            )
                                        )}

                                    </div>
                                </Col>

                                <Col lg={3} className='d-flex align-items-center justify-content-center'>
                                    <div className='d-flex align-items-center'>
                                        {/* <Link to={"/cart"} className='text-secondary d-flex align-items-center position-relative'>
                                            <img src={cartImg} alt="" />
                                            {cartCount > 0 && (
                                                <span className='cart-count-badge'>{cartCount}</span>
                                            )}
                                        </Link>
                                        <span className='blue mx-1'><img src={linImg} alt="" /></span> */}
                                        <Link to={"/myaccount"} className='text-secondary d-flex align-items-center'><img src={loginImg} alt="" /></Link>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='line' />
                        <Container>
                            <Row>
                                <Col sm={10}>
                                    <Container>
                                        <div className=''>
                                            <div className="nav">
                                                <nav>
                                                    <ul className="menu menu-bar">
                                                        <li className='me-5'>
                                                            <Link to={"/product-category/all-products/"} className="menu-link p-1 border-none menu-bar-link" aria-haspopup="true">
                                                                <CgMenuRight className='me-2 blue' />
                                                                دسته بندی محصولات
                                                            </Link>
                                                        </li>
                                                        <li className='px-2'><Link to={'/'}>صفحه اصلی</Link></li>
                                                        <li className='px-2'><Link to={'/reservation'}>رزرو نوبت</Link></li>
                                                        <li className='px-2'><Link to={"/introduction/"}>معرفی آپگریدر</Link></li>
                                                        {/*<li className='px-2'><Link to={'/پیگیری-سفارشات/'}>پیگیری سفارشات</Link></li>*/}
                                                        <li className='px-2'><Link to={"/category/خدمات-مجموعه-آپگریدر/"}>خدمات مجموعه</Link></li>
                                                        <li className='px-2'><Link to={"/contact-us/"}>تماس با ما</Link></li>
                                                        <li className='px-2'><Link to={"/blog/"}>مقالات</Link></li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </Container>
                                </Col>
                            </Row>
                        </Container>
                        <Container className='line' />
                    </div>
                </header>
            ) : (
                <header>
                    <Container fluid className='py-3 header-phone'>
                        <Row className='align-items-center'>
                            <Col xs={3} className='px-0'>
                                <Button variant="" className='logo-phone rounded-0 px-4 py-2' onClick={handleShow}>
                                    <CgMenuRight className='fs-1 blue' />
                                </Button>

                                <Offcanvas show={show} className="mega-menu-phone" onHide={handleClose}>
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title className='blue'>آپگریدر</Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <ul style={{ listStyle: "none" }} className='ps-2 mt-4'>
                                            <li className='py-3'><Link onClick={handleClose} to={"/"}>صفحه اصلی</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={"/product-category/all-products/"}>دسته بندی محصولات</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={"/introduction/"}>معرفی آپگریدر</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={'/پیگیری-سفارشات/'}>پیگیری سفارشات</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={"/contact-us/"}>تماس باما</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={"/category/خدمات-مجموعه-آپگریدر/"}>خدمات مجموعه</Link></li>
                                            <li className='py-3'><Link onClick={handleClose} to={"/blog/"}>مقالات</Link></li>
                                            <li className='py-3'><Link onClick={handleClose}>رزرو نوبت</Link></li>
                                        </ul>
                                    </Offcanvas.Body>
                                </Offcanvas>
                            </Col>
                            <Col xs={6} className='pe-2 ps-0'>
                                <div className='text-center'>
                                    <Form style={{ position: 'relative' , height : "30px" }}>
                                        <Form.Control
                                            className='search-input-m'
                                            placeholder='جستجو...'
                                            type='text'
                                            onChange={changeValue} // Handle the input change
                                            value={search} // Bind the input to the search state
                                        />
                                        {searchProduct.length > 0 ? (
                                            <div className='search-box-content'>
                                                <Row className='gy-3'>
                                                    {searchProduct.map((ptc, index) => (
                                                        <Col key={index} xs={12}>
                                                            <Link to={'/product/' + ptc.slug}>
                                                                <div className='search-card'>
                                                                    <div>
                                                                        <img
                                                                            className='img-fluid'
                                                                            src={apiKey + ptc.photo}
                                                                            alt={ptc.name}
                                                                        />
                                                                    </div>
                                                                    <div>{ptc.name}</div>
                                                                    <div className=''>
                                                                        {ptc.price > 0 ?
                                                                            <>

                                                                                <span className="me-2" style={{ marginTop: "10px" }}>{ptc.price}</span>
                                                                                <img src={toman} style={{ width: "25px" }} alt="toman" />
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <span className='text-danger text-sm'>تماس بگیرید</span>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        ) : (
                                            search.length > 2 && (
                                                <div className='search-box-content'>
                                                    <div className="no-results-found">
                                                        <p className="text-center mt-3" style={{fontSize : "12px"}}>نتیجه‌ای یافت نشد.</p>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </Form>
                                </div>
                            </Col>

                            <Col xs={3} className='px-0 text-end'>
                                <div>
                                    <div className='px-2 login-btn'>
                                        <div className='d-flex align-items-center justify-content-end'>
                                            <Link to={"/cart"} className='text-secondary d-flex align-items-center position-relative'>
                                                <img src={cartImg} style={{ width: "20px" }} alt="" />
                                                {cartCount > 0 && (
                                                    <span className='cart-count-badge'>{cartCount}</span>
                                                )}
                                            </Link>
                                            <span className='blue mx-1'><img src={linImg} style={{ width: "15px" }} alt="" /></span>
                                            <Link to={"/myaccount"} className='text-secondary d-flex align-items-center'><img src={loginImg} style={{ width: "30px" }} alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </header>
            )}
        </>
    );
}
