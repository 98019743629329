import { Container, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import NavLinks from '../NavLinks/NavLinks';
import React, { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import "./SingleProduct.css";
import {PiHeartbeatThin, PiHeartFill, PiHeartThin} from "react-icons/pi";
import Related from './Related';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import addtocart from '../../Image/addtocart.png';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import axiosClient from "../../axios-client";
import Cookies from 'js-cookie';
import Review from '../Review/Review';
import NotFound from '../NotFound/NotFound';
import {useStateContext} from "../context/ContextProvider";
import loader from "../../Image/loader.gif";

export default function SingleProduct() {
    let { productSlug } = useParams();
    const [product, setProduct] = useState({});
    // const [loading, setLoading] = useState(false);
    const apiKey = process.env.REACT_APP_API_KEY;
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [allTypes, setAllTypes] = useState([]);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [count, setCount] = useState(1);
    const [value, setValue] = useState('1');
    const [cart, setCart] = useState([]);
    const [rate, setRate] = useState(0);
    const [error, setError] = useState(null);

    const [selectedTypeOne, setSelectedTypeOne] = useState('');
    const [selectedTypeTwo, setSelectedTypeTwo] = useState('');
    const [selectedTypeThree, setSelectedTypeThree] = useState('');
    const [currentPrice, setCurrentPrice] = useState(0);

    const [isWishListed, setIsWishListed] = useState(false);
    const {customer,token} = useStateContext();


    useEffect(() => {
        getProduct();
        loadCart();
    }, [productSlug]);

    useEffect(() => {
        if (product) {
            const typeOnes = (product.type_ones || []).map(typeOne => ({
                ...typeOne,
                id: `type_one_${typeOne.id}`
            }));

            const typeTwos = (product.type_twos || []).map(typeTwo => ({
                ...typeTwo,
                id: `type_two_${typeTwo.id}`
            }));

            const typeThrees = (product.type_threes || []).map(typeThree => ({
                ...typeThree,
                id: `type_three_${typeThree.id}`
            }));
            const product_images = (product.product_images || []).map(product_images => ({
                ...product_images,
                id: `product_images${product_images.id}`
            }));

            const combinedTypes = [product, ...typeOnes, ...typeTwos, ...typeThrees, ...product_images];

            setAllTypes(combinedTypes);

            // Set initial price to product's base price
            setCurrentPrice(product.price || 0);
        }
    }, [product]);

    // the wishList button

    useEffect(() => {
        if (product.id && customer && token) {
            checkWishListStatus();
        }

    }, [product.id, customer]);

    const checkWishListStatus = async () => {
        try {
            const response = await axiosClient.get(`/wish-list/check/${product.id}`,{
                params: { customerId: customer}
            });
            setIsWishListed(response.data.isWishListed);

        } catch (error) {
            console.error("Failed to check wish list status:", error);

        }
    };

    const toggleWishList = async () => {

        try {
            if (isWishListed) {
                // Remove from wish list
                await axiosClient.delete(`/wish-list/remove`, {
                    data: { productId: product.id, customerId: customer}
                })
                    .then()
                    .catch((err)=>{
                        checkWishListStatus();
                    });
            } else {
                // Add to wish list
                await axiosClient.post(`/wish-list/add`, { productId: product.id, customerId: customer });
            }

            setIsWishListed(!isWishListed);
        } catch (error) {

            console.error("Failed to toggle wish list:", error);
        }
    };



    const getProduct = () => {
        // setLoading(true);

        axiosClient.get(`/getProduct/${productSlug}`)
            .then(({ data }) => {
                // setLoading(false);
                const comments = data.data.product_comments || [];
                const averageRate = comments.length > 0 ? comments.reduce((a, v) => a + v.rate, 0) / comments.length : 0;
                setRate(averageRate);
                setProduct(data.data);
                setCurrentPrice(data.data.price || 0); // Initialize price

            })
            .catch(err => {
                // setLoading(false);
                if (err.response && err.response.status === 404) {
                    setError('Product not found');
                } else {
                    console.error(err);
                }
            });
    };

    const handleTypeOneChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTypeOne(selectedValue);
        setSelectedTypeTwo('');
        setSelectedTypeThree('');
        updatePriceAndSwiper(selectedValue, 'type_one');
    };

    const handleTypeTwoChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTypeTwo(selectedValue);
        setSelectedTypeOne('');
        setSelectedTypeThree('');
        updatePriceAndSwiper(selectedValue, 'type_two');
    };

    const handleTypeThreeChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedTypeThree(selectedValue);
        setSelectedTypeOne('');
        setSelectedTypeTwo('');
        updatePriceAndSwiper(selectedValue, 'type_three');
    };

    const updatePriceAndSwiper = (selectedValue, typeCategory) => {
        const selectedType = allTypes.find(type => type.id === selectedValue);
        if (selectedType) {
            // Update price
            setCurrentPrice(selectedType.price || product.price || 0);

            // Update Swiper
            const selectedIndex = allTypes.findIndex(type => type.id === selectedValue);
            if (swiperInstance && selectedIndex !== -1) {
                swiperInstance.slideTo(selectedIndex);
            }
        } else {
            // If no type is selected, revert to base product price
            setCurrentPrice(product.price || 0);
        }
    };

    const plusCount = () => {
        setCount(prevCount => {
            if (prevCount < product.amount) {
                return prevCount + 1; // Increment count if it's less than the product amount
            }
            return prevCount; // Keep the count the same if it reaches the product amount
        });
    };


    const minesCount = () => {
        if (count !== 1) {
            setCount(prevCount => prevCount - 1);
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const generateUserId = () => {
        let userId = Cookies.get('user_id');
        if (!userId) {
            userId = 'user_' + Math.random().toString(36).substr(2, 9);
            Cookies.set('user_id', userId, { expires: 1 });
        }
        return userId;
    };

    const saveCart = (newCart) => {
        Cookies.set('cart', JSON.stringify(newCart), { expires: 7 });
        setCart(newCart);
    };

    const loadCart = () => {
        const cartFromCookie = Cookies.get('cart');
        if (cartFromCookie) {
            setCart(JSON.parse(cartFromCookie));
        }
    };

    const addToCart = () => {
        const userId = generateUserId();

        // Determine the selected type details
        let selectedType = null;
        if (selectedTypeOne) {
            selectedType = allTypes.find(type => type.id === selectedTypeOne);
        } else if (selectedTypeTwo) {
            selectedType = allTypes.find(type => type.id === selectedTypeTwo);
        } else if (selectedTypeThree) {
            selectedType = allTypes.find(type => type.id === selectedTypeThree);
        }

        const newCartItem = {
            productId: product.id,
            count,
            userId,
            selectedType: selectedType ? selectedType.id : null,
            price: currentPrice
        };

        const newCart = [...cart, newCartItem];
        saveCart(newCart);
    };

    if (error) {
        return <NotFound />;
    }

    // if (loading) {
    //     // Display loader while the page is loading
    //     return (
    //         <div className="preloader">
    //             <img src={loader} alt="Loading..." />
    //         </div>
    //     );
    // }
    const link = [{
        name: product.name,
        link: '/product/' + product.slug,
    }];







    // Check if product is in the wish list when the component mounts


    return (
        <>
            <NavLinks {...link} />
            <Container className='mt-3 mt-lg-5'>
                <Row>
                    <Col lg={7} className='order-2 order-lg-1'>
                        <div className='row justify-content-between'>
                            <Col lg={8} className='d-none d-lg-block'><h1>{product.name}</h1>
                                <span className='text-muted'>{product.amount > 0 ? (<span className='text-success'>موجود</span>) : (<span className='text-danger'>ناموجود</span>)}</span>
                            </Col>

                            <Col lg={4} className='text-center text-lg-end'>
                                <Rating
                                    style={{ direction: "ltr" }}
                                    size='large'
                                    name="text-feedback"
                                    value={rate}
                                    readOnly
                                    precision={0.1}
                                />
                            </Col>
                        </div>
                        <div className='my-3' style={{ fontWeight: "200" }}>
                            <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: product.describtion }} />
                        </div>
                        <div className='my-3 box-border'>
                            {/* Type One Selection */}
                            {product.type_one && (
                                <div className='d-flex justify-content-between mb-3'>
                                    <span className='fs-4'>{product.type_one} :</span>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id='type-one-select-label'>
                                                {product.type_one}
                                            </InputLabel>
                                            <Select
                                                labelId='type-one-select-label'
                                                id='type-one-select'
                                                value={selectedTypeOne}
                                                onChange={handleTypeOneChange}
                                                label={product.type_one}
                                            >
                                                <MenuItem value="">
                                                    <em>انتخاب کنید</em>
                                                </MenuItem>
                                                {product.type_ones.map((typeOne) => (
                                                    <MenuItem
                                                        value={'type_one_' + typeOne.id}
                                                        key={typeOne.id}
                                                    >
                                                        {typeOne.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            )}

                            {/* Type Two Selection */}
                            {product.type_two && (
                                <div className='d-flex justify-content-between mb-3'>
                                    <span className='fs-4'>{product.type_two} :</span>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id='type-two-select-label'>
                                                {product.type_two}
                                            </InputLabel>
                                            <Select
                                                labelId='type-two-select-label'
                                                id='type-two-select'
                                                value={selectedTypeTwo}
                                                onChange={handleTypeTwoChange}
                                                label={product.type_two}
                                            >
                                                <MenuItem value="">
                                                    <em>انتخاب کنید</em>
                                                </MenuItem>
                                                {product.type_twos.map((typeTwo) => (
                                                    <MenuItem
                                                        value={'type_two_' + typeTwo.id}
                                                        key={typeTwo.id}
                                                    >
                                                        {typeTwo.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            )}

                            {/* Type Three Selection */}
                            {product.type_three && (
                                <div className='d-flex justify-content-between mb-3'>
                                    <span className='fs-4'>{product.type_three} :</span>
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControl fullWidth>
                                            <InputLabel id='type-three-select-label'>
                                                {product.type_three}
                                            </InputLabel>
                                            <Select
                                                labelId='type-three-select-label'
                                                id='type-three-select'
                                                value={selectedTypeThree}
                                                onChange={handleTypeThreeChange}
                                                label={product.type_three}
                                            >
                                                <MenuItem value="">
                                                    <em>انتخاب کنید</em>
                                                </MenuItem>
                                                {product.type_threes.map((typeThree) => (
                                                    <MenuItem
                                                        value={'type_three_' + typeThree.id}
                                                        key={typeThree.id}
                                                    >
                                                        {typeThree.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </div>
                            )}

                            {/* Price Display */}
                            <div className='d-flex justify-content-between mt-4'>
                                <span className='fs-4 mt-4'>قیمت :</span>
                                <div>

                                    {product.price > 0 ? (
                                        <div>
                                            <span className="main-price fs-4 me-2 mt-2">{product.discount}</span>
                                            <br></br>
                                            <span className='fs-4 me-2 mt-2'>{currentPrice} تومان</span>
                                        </div>

                                    ) : (
                                        <span className='fs-4 me-2 mt-2 text-danger'> تماس بگیرید</span>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className='my-5'>
                            <Row className='justify-content-around'>

                                <Col xs={3} className='single-btns'>
                                    <button onClick={plusCount} className='border-0 px-lg-3 bg-transparent'><span style={{ color: "#f6a413" }}>+</span></button>
                                    <span>{count}</span>
                                    <button onClick={minesCount} className='border-0 px-lg-3 bg-transparent'><span style={{ color: "#f6a413" }}>_</span></button>
                                </Col>
                                {/*<Col xs={8} lg={6} className="mx-2 mx-lg-3">*/}
                                {/*    {product.amount > 0 && product.price > 0 ? (*/}
                                {/*        <Link className="single-btns" onClick={addToCart} to="/cart">*/}
                                {/*            <span className="fs-6">اضافه کردن به سبد خرید</span>*/}
                                {/*            <span>*/}
                                {/*                <img src={addtocart} alt="addtocart" />*/}
                                {/*            </span>*/}
                                {/*        </Link>*/}
                                {/*    ) : (*/}
                                {/*        <div className="single-btns disabled">*/}
                                {/*            <span className="fs-6 text-muted">اضافه کردن به سبد خرید</span>*/}
                                {/*            <span>*/}
                                {/*                <img src={addtocart} alt="addtocart" style={{ opacity: 0.5 }} />*/}
                                {/*            </span>*/}
                                {/*        </div>*/}
                                {/*    )}*/}
                                {/*</Col>*/}

                                {/* For now the Cart is disable*/}
                                <Col xs={8} lg={6} className="mx-2 mx-lg-3">
                                    <div className="single-btns disabled">
                                                    <span className="fs-6 text-muted">اضافه کردن به سبد خرید</span>
                                                    <span>
                                                        <img src={addtocart} alt="addtocart" style={{ opacity: 0.5 }} />
                                                    </span>
                                    </div>
                                </Col>




                                    {customer && token &&
                                        <Col xs={2} className='single-btns justify-content-center d-none d-lg-block'>
                                        <button onClick={toggleWishList} className='border-0 px-lg-3 bg-transparent'>
                                            {isWishListed ?
                                                <PiHeartFill className='fs-1' style={{color: "#f6a413"}}/>
                                                :
                                                <PiHeartThin className='fs-1' style={{color: "#f6a413"}}/>}
                                        </button>
                                        </Col>
                                    }



                            </Row>
                        </div>
                        <Box sx={{width: '100%', typography: 'body1'}}>
                            <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                        <Tab className='fs-6' label="توضیحات" value="1" />
                                        <Tab className='fs-6' label="مشخصات" value="2" />
                                        <Tab className='fs-6' label="نظرات" value="3" />
                                    </TabList>
                                </Box>

                                <TabPanel value="1">
                                    <div style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: product.article }}></div>
                                </TabPanel>

                                <TabPanel value="2">
                                    {product.product_properties && (
                                        <div className='text-center' style={{ width: "80%" }}>
                                            {product.product_properties.map((property, index) => (
                                                <div className='d-flex justify-content-between my-3' key={index}>
                                                    <span>{property.name}</span>
                                                    <span>{property.describtion}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </TabPanel>

                                <TabPanel value="3"><Review productId={product.id} /></TabPanel>
                            </TabContext>
                        </Box>
                    </Col>
                    <Col lg={5} className='order-1 order-lg-2'>
                        <div style={{ position: 'sticky', top: '25px' }}>
                            <Swiper
                                onSwiper={setSwiperInstance}
                                style={{
                                    '--swiper-navigation-color': '#000',
                                    '--swiper-pagination-color': '#000',
                                    position: 'sticky',
                                }}
                                spaceBetween={10}
                                navigation={true}
                                thumbs={{ swiper: thumbsSwiper && thumbsSwiper.destroyed !== true ? thumbsSwiper : null }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className='album2'
                            >
                                {allTypes && (
                                    allTypes.map((type, index) => (
                                        <SwiperSlide key={index} id={type.id}>
                                            <img
                                                src={apiKey + type.photo}
                                                className='img-fluid rounded shadow'
                                                alt={type.name}
                                            />
                                        </SwiperSlide>
                                    ))
                                )}
                            </Swiper>

                            <Swiper
                                onSwiper={setThumbsSwiper}
                                spaceBetween={10}
                                slidesPerView={4}
                                freeMode={true}
                                watchSlidesProgress={true}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className='album'
                            >
                                {allTypes.map((type, index) => (
                                    <SwiperSlide key={index} id={type.id}>
                                        <img
                                            src={apiKey + type.photo}
                                            className='img-fluid rounded'
                                            alt={type.name}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Col>
                </Row>
            </Container >
            <section className='my-5'>
                <Container fluid className='p-0 py-3 mt-5 py-lg-5'>
                    <div className='p-0 d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img style={{ width: "40px" }} src="http://localhost:3000/image/miniLine.png" alt="" />
                            <h5 className='ms-4 blue sec-title'>محصولات مشابه</h5>
                        </div>
                        <div className='d-flex align-items-center '>
                            <h5 className='ms-4 blue me-3 sec-title'>مشاهده بیشتر</h5>

                            <img className='me-2' style={{ width: "30px", marginTop: "-7px" }} src="http://localhost:3000/image/rows.png" alt="" />
                        </div>
                    </div>
                </Container>
                <Container>
                    <Related />
                </Container>

            </section>
        </>
    )
}
