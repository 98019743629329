import React, { useState } from 'react';
import NavLinks from '../NavLinks/NavLinks';
import { Col, Container, Row } from 'react-bootstrap';
import "./Reservation.css";
import { FaMapMarkerAlt } from "react-icons/fa";
import map from "./../../Image/res-map.png";
import { Link, Outlet , useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import logo from "./../../Image/logo.png"
import line from "./../../Image/linlink.png"
import Servise from './Components/Service/service';
import Information from './Components/Information/Information';
import DateTime from './Components/Date&time/Date&Time';
import Confirm from './Components/Confirm/Confirm';

export default function Reservation() {
    const [level , setLevel] = useState(1)
    const location = useLocation()
    return (
        <Container>
            <NavLinks />
            <div className='text-center reserv-title'>
                به صفحه رزرو نوبت مجموعه <span style={{ color: "#F6A413" }}>آپگریدر</span> خوش آمدید .
            </div>
            <div className='text-center reserv-title-2 mt-3'>
                این بخش به منظور صرفه جویی در وقت و ارائه خدمات به موقع و بی نقص به شما مشتریان عزیز راه اندازی شده است .
            </div>
            <Row className='res-address align-items-center my-4'>
                <Col lg={10} className='d-flex align-items-center'>
                    <span><FaMapMarkerAlt /></span>
                    <p className='m-0 ms-4'>
                    تهران_کنارگذر شهید متوسلیان (فتح) به سمت غرب_ بعد از فتح ۲۹ _ مجتمع کوشانگار (آبادان) _مجموعه آپگریدر 
                    </p>
                </Col>
                <Col lg={2} className='text-end text-center'>
                    <a href='https://maps.app.goo.gl/Gn3w5tWbvKyDdB6A7'>
                        <span>مسیر یابی</span>
                        <span><img src={map} style={{ width: "30px" }} alt="" /></span>
                    </a>
                </Col>
            </Row>
            <div className='box-tip d-none d-lg-block'>
                لطفاً قبل از رزرو نکات زیر را مطالعه نمایید .<br />
                مبلغ پرداختی شما صرفاً جهت احترام به وقت همدیگر بوده و پس از اتمام کار<span style={{ color: "#ff0000" }}> از کل مبلغ فاکتور کسر می گردد</span>.<br />
                رزرو وقت قبل از مراجعه حضوری به مجموعه <span style={{ color: "#ff0000" }}>الزامی</span> می باشد .<br />
                برای خدمات مکانیکی لطفاً <span style={{ color: "#ff0000" }}>2 ساعت</span> قبل از وقت رزرو شده در محل حضور داشته باشید تا خودرو سرد شود .<br />
                <span style={{ color: "#ff0000" }}>برای مشکلات برقی سنگین قبل از رزرو وقت حتماً با مجموعه تماس حاصل فرمایید</span> .<br />
                برای خدمات برقی نیازی به سرد شدن خودرو نمی باشد و تنها برای انجام امور پذیرش خودرو <span style={{ color: "#ff0000" }}>10 دقیقه</span> قبل از وقت رزرو شده در محل حضور داشته باشید .<br />
                لغو رزرو <span style={{ color: "#ff0000" }}>تا 12 ساعت قبل</span> از وقت رزرو شده امکان پذیر می باشد.<br />
                از ساعت <span style={{ color: "#ff0000" }}>14:00 الی 14:45</span> وقت ناهار مجموعه می باشد و در این بازه زمانی خدمتی ارائه نمی شود.<br />
                در صورت عدم مراجعه شما در وقت رزرو شده مبلغ پرداخت شده <span style={{ color: "#ff0000" }}>قابل برگشت نمی باشد</span>.<br />
                <span style={{ color: "#ff0000" }}>رزرو روز جمعه</span>: توجه فرمایید روز جمعه مناسب خدمات معمول خودرویی می باشد و مشکلات برقی که نیازمند زمان زیاد برای عیب یابی هستند در روزهای جمعه پذیرش نمی شوند.<br />
                <span style={{ color: "#ff0000" }}>لطفاً از رزرو وقت برای مشکلات برقی در روز جمعه خودداری فرمایید</span>.<br />
                در وار د اندی ممکن است خللی در رفع مشکل خودروی مشتری که قبل از شما مراجعه کرده است به وجود آید و این موضوع ممکن است باعث شود حداکثر به میزان 30 دقیقه ارائه خدمات به خودروی شما به تعویق بیفتد. از صبر و درک شما مشتری عزیز متشکریم.
            </div>
            <Accordion defaultActiveKey="" className="d-lg-none box-tip">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>لطفاً قبل از رزرو نکات زیر را مطالعه نمایید</Accordion.Header>
                    <Accordion.Body>
                        لطفاً قبل از رزرو نکات زیر را مطالعه نمایید .<br />
                        مبلغ پرداختی شما صرفاً جهت احترام به وقت همدیگر بوده و پس از اتمام کار<span style={{ color: "#ff0000" }}> از کل مبلغ فاکتور کسر می گردد</span>.<br />
                        رزرو وقت قبل از مراجعه حضوری به مجموعه <span style={{ color: "#ff0000" }}>الزامی</span> می باشد .<br />
                        برای خدمات مکانیکی لطفاً <span style={{ color: "#ff0000" }}>2 ساعت</span> قبل از وقت رزرو شده در محل حضور داشته باشید تا خودرو سرد شود .<br />
                        <span style={{ color: "#ff0000" }}>برای مشکلات برقی سنگین قبل از رزرو وقت حتماً با مجموعه تماس حاصل فرمایید</span> .<br />
                        برای خدمات برقی نیازی به سرد شدن خودرو نمی باشد و تنها برای انجام امور پذیرش خودرو <span style={{ color: "#ff0000" }}>10 دقیقه</span> قبل از وقت رزرو شده در محل حضور داشته باشید .<br />
                        لغو رزرو <span style={{ color: "#ff0000" }}>تا 12 ساعت قبل</span> از وقت رزرو شده امکان پذیر می باشد.<br />
                        از ساعت <span style={{ color: "#ff0000" }}>14:00 الی 14:45</span> وقت ناهار مجموعه می باشد و در این بازه زمانی خدمتی ارائه نمی شود.<br />
                        در صورت عدم مراجعه شما در وقت رزرو شده مبلغ پرداخت شده <span style={{ color: "#ff0000" }}>قابل برگشت نمی باشد</span>.<br />
                        <span style={{ color: "#ff0000" }}>رزرو روز جمعه</span>: توجه فرمایید روز جمعه مناسب خدمات معمول خودرویی می باشد و مشکلات برقی که نیازمند زمان زیاد برای عیب یابی هستند در روزهای جمعه پذیرش نمی شوند.<br />
                        <span style={{ color: "#ff0000" }}>لطفاً از رزرو وقت برای مشکلات برقی در روز جمعه خودداری فرمایید</span>.<br />
                        در وار د اندی ممکن است خللی در رفع مشکل خودروی مشتری که قبل از شما مراجعه کرده است به وجود آید و این موضوع ممکن است باعث شود حداکثر به میزان 30 دقیقه ارائه خدمات به خودروی شما به تعویق بیفتد. از صبر و درک شما مشتری عزیز متشکریم.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Row className='res-form my-5'>
                <Col lg={3} className='text-center res-steps-col d-none d-lg-block'>
                    <img className='img-fluid' src={logo} alt="" />
                    <ul className='res-steps mt-5 mx-4'>
                        <Link>
                            <li className= {`res-step ${level === 1 ? "res-step-active" :  "" }`} > <img src={line} className='img-fluid me-3' alt="" />سرویس ها</li>
                        </Link>
                        <Link>
                        <li className= {`res-step ${level === 2 ? "res-step-active" :  "" }`} > <img src={line} className='img-fluid me-3' alt="" />تاریخ و زمان</li>
                        </Link>
                        <Link>
                        <li className= {`res-step ${level === 3 ? "res-step-active" :  "" }`} > <img src={line} className='img-fluid me-3' alt="" />اطلاعات</li>
                        </Link>
                        <Link>
                        <li className= {`res-step ${level === 4 ? "res-step-active" :  "" }`} > <img src={line} className='img-fluid me-3' alt="" />تایید و پرداخت</li>
                        </Link>
                    </ul>
                </Col>
                <Col lg={9}>
                    {/* <Outlet /> */}
                    {level === 1 ? <Servise setLevel={setLevel} /> : ""}
                    {level === 2 ? <DateTime setLevel={setLevel} /> : ""}
                    {level === 3 ? <Information setLevel={setLevel} /> : ""}
                    {level === 4 ? <Confirm setLevel={setLevel} /> : ""}
                </Col>
            </Row>

        </Container>
    );
}
